import HighchartsReact from "highcharts-react-official";
import React from "react";
import Highcharts from "highcharts";
import "../styles/chart.css";

const ChartMain = ({ data }) => {
  const { eur, gau, gbp, cad, aud, chf } = data;

  const options = { style: "currency", currency: "USD" };
  const numberFormat = new Intl.NumberFormat("en-US", options);

  const configPrice = {
    chart: {
      zoomType: "x",
      height: 600,
    },

    yAxis: {
      labels: {
        formatter: function () {
          return numberFormat.format(this.value);
        },
      },
      plotLines: [
        {
          value: 0,
          width: 5,
          color: "silver",
        },
      ],
      opposite: false,
    },

    tooltip: {
      formatter: function () {
        let timestamp = this.x;
        const newDate = new Date(timestamp * 1000);
        const weekdays = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const weekday = weekdays[newDate.getUTCDay()];
        const month = months[newDate.getUTCMonth()];
        const day = newDate.getUTCDate().toString();
        const year = newDate.getUTCFullYear().toString();
        const formatedDate = `${weekday}, ${month} ${day}, ${year}`;
        const options = { maximumFractionDigits: 4 };
        const formatted = new Intl.NumberFormat("en-US", options);

        return [formatedDate].concat(
          this.points
            ? this.points.map(function (point) {
                return point.series.name + ": " + formatted.format(point.y);
              })
            : []
        );
      },
      split: true,
      crosshairs: true,
      valueDecimals: 3,
    },

    plotOptions: {
      series: {
        compare: "price",
        showInNavigator: true,
      },
    },

    navigator: {
      enabled: false,
    },

    rangeSelector: {
      inputEnabled: false,
      label: false,
      buttonTheme: {
        // styles for the buttons
        fill: "none",
        stroke: "none",
        "stroke-width": 0,
        r: 8,
        width: 38,
        style: {
          color: "#039",
          fontWeight: "bold",
        },
      },
      buttons: [
        {
          type: "all",
          text: "Reset",
        },
      ],
      selected: 1,
    },

    credits: {
      enabled: false,
    },

    title: {
      text: undefined,
    },

    legend: {
      enabled: true,
    },

    xAxis: {
      labels: {
        enabled: false,
      },
    },

    series: [
      {
        name: "GAU",
        type: "spline",
        data: gau,
        color: "#FF0000",
      },
      {
        name: "EUR",
        type: "spline",
        data: eur,
        color: "#000",
        visible: false,
      },
      {
        name: "CAD",
        type: "spline",
        data: cad,
        color: "#38E54D",
        visible: false,
      },
      {
        name: "AUD",
        type: "spline",
        data: aud,
        color: "#6F38C5",
        visible: false,
      },
      {
        name: "GBP",
        type: "spline",
        data: gbp,
        color: "#0008C1",
        visible: false,
      },
      {
        name: "CHF",
        type: "spline",
        data: chf,
        color: "#FF731D",
        visible: false,
      },
    ],
  };

  return (
    <div className="chart">
      <h1 className="chartName">
        GAUGECASH against major fiat Currencies since the Euro Inception in 2002
      </h1>
      <HighchartsReact highcharts={Highcharts} options={configPrice} />
    </div>
  );
};

export default ChartMain;
