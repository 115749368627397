import React, { useEffect, useState } from "react";
import ChartMain from "../_pages/ChartMain";
import "../styles/chart.css";

export const Chart = () => {
  const [eur, setEur] = useState([]);
  const [gau, setGau] = useState([]);
  const [gbp, setGbp] = useState([]);
  const [chf, setChf] = useState([]);
  const [aud, setAud] = useState([]);
  const [cad, setCad] = useState([]);

  useEffect(() => {
    eurUsd("eurusd");
    gauUSd("gauusd");
    gbpUsd("gbpusd");
    chfUsd("chfusd");
    audUSd("audusd");
    cadUsd("cadusd");
  }, []);

  const epoch = (file) => {
    var myDate = new Date(file);
    var myEpoch = myDate.getTime() / 1000.0;

    return myEpoch;
  };

  const request = async (text) => {
    try {
      const query = await fetch(`http://3.138.56.108:8081/${text}`);

      const files = await query.json();

      const convert = Object.values(files);

      const modify = convert.map((e) => {
        return [epoch(e.datetime), e.close];
      });

      return modify;
    } catch (error) {
      console.log(error);
    }
  };

  const eurUsd = async (req) => {
    const data = await request(req);
    setEur(data);
  };

  const gauUSd = async (req) => {
    const data = await request(req);
    setGau(data);
  };

  const gbpUsd = async (req) => {
    const data = await request(req);
    setGbp(data);
  };

  const chfUsd = async (req) => {
    const data = await request(req);
    setChf(data);
  };

  const audUSd = async (req) => {
    const data = await request(req);
    setAud(data);
  };

  const cadUsd = async (req) => {
    const data = await request(req);
    setCad(data);
  };

  const datas = { eur, gau, gbp, cad, aud, chf };

  return <>{eur ? <ChartMain data={datas} /> : "..loading"}</>;
};
